export const dutchTranslation = {
    de: "Duits",
    fr: "Frans",
    it: "Italiaans",
    nl: "Nederlands",

    DE: "Duitsland",
    FR: "Frankrijk",
    IT: "Italië",
    NL: "Nederland",
    BE: "Belgie",
    CH: "Zwitserland",
    AT: "Oostenrijk",
    LU: "Luxemburg",

    yes: "Ja",
    no: "Nee",
    ok: "OK",
    cancel: "Afbreken",
    close: "Sluiten",
    read: "Lezen",
    app_info: "prowin.app - De informatieportal voor uw verkoopactiviteiten",
    logout_success: "Je bent succesvol afgemeld",
    login_again: "Weer aanmelden",
    to_prowin_website:"Naar de proWIN-website",
    thank_you: "Bedankt voor je bezoek, we wensen je veel succes en plezier met proWIN.",
    go_back_original_account: "Terug naar het originele account",
    switch_account: "Ander account",
    no_subaccount: "Geen onderliggend account beschikbaar",
    select_country: "Land kiezen",
    select_language: "Taal kiezen",
    change_country: "Land wijzigen",
    change_language: "Taal wijzigen",

    error_back_to_home: "<small>Go back <a href=\"/\">to the dashboard</a>.</small>",
    error_not_found_title: "Page not Found",
    error_not_found_text: "Could not find the requested page. Are you sure it exists?",
    error_loading_title: "Loading Error",
    error_loading_text: "",
    error_unknown_title: "Unknown Error",
    error_unknown_text: "",
    error_occured: "Er is een fout opgetreden.",

    dashboard_title: "Welkom bij proWIN international",
    dashboard_intro: "Microfrontend App Shell - Dashboard",
    demo_page_title: "Voorbeeldpagina",

    home: "Startpagina",

    pwa_install: "Installeren",
    pwa_reinstall: "Vernieuw de pagina om de installatieknop zichtbaar te maken.",
    pwa_prompt_install: "Download de proWIN app.",
    pwa_enable_push: "Web-app toevoegen aan het beginscherm om push-meldingen te activeren",

    menu_label: "Menu",
    menu_home: "Startpagina",
    menu_general_start_page: "Startpagina",
    menu_general_faq: "Kennis",
    menu_general_information: "Kennis",
    menu_general_events: "Data",
    menu_general_competitions: "Wedstrijden",
    menu_general_products: "Producten",
    menu_general_current: "Verkoop",
    menu_product_information: "Productinfo",
    menu_product_shop: "Shop",
    menu_product_complaints: "Reclamatie",
    menu_general_myprowin: "Mijn proWIN",
    menu_myprowin_parties: "Demo's",
    menu_myprowin_distribution_application: "Verkooppartnerovereenkomst",
    menu_myprowin_invoices: "Facturen & provisie afrekeningen",
    menu_myprowin_reporting: "Reporting",
    menu_myprowin_distribution_website: "Verkooppartnerpagina",
    menu_myprowin_planning: "Planningstool",
    menu_elearning: "E-learning",
    menu_media: "proWIN Media",

    menu_subline_product_marketing: "Reclamemateriaal bestellen",
    menu_subline_product_info: "Algemene productinformatie",
    menu_subline_reporting: "Rapportages uit mijn structuur",
    menu_subline_reclamation: "Producten reclameren",
    menu_subline_shop: "Producten bestellen",
    menu_subline_media: "De grote videoportal",
    menu_subline_academy: "Kom meer te weten over het werk als consulent",
    menu_subline_party_planning: "Mijn volgende demo plannen",
    menu_subline_structure: "Afspraken binnen mijn structuur aanmaken en beheren",
    menu_subline_vp_webseite: "Eigen partnerwebsite bewerken",
    menu_subline_faq: "Kennisdatabase om op te zoeken",

    menu_name_product_marketing: "Marketing-shop",
    menu_name_product_info: "Productinformatie",
    menu_name_reporting: "Reporting",
    menu_name_blumer: "Blumer",
    menu_name_reclamation: "Reclamatie",
    menu_name_shop: "Shop",
    menu_name_media: "proWIN Media",
    menu_name_academy: "Online Akademie",
    menu_name_party_planning: "Demo-planning",
    menu_name_vp_webseite: "Mijn VP-pagina",
    menu_name_structure: "Structuurafspraken",
    menu_name_faq: "Verkoopkennis",

    user_menu_latest: "Aktueel nieuws",
    user_menu_knowledge: "Kennis",
    user_menu_dark_mode: "Dark Mode",
    user_menu_light_mode: "Light Mode",
    user_menu_profile: "Profiel",
    user_menu_tasks: "Taken",
    user_menu_select_language: "Taal wijzigen",
    user_menu_my_account: "Mijn account",
    user_menu_settings: "Instellingen",
    user_menu_messages: "Berichten",
    user_menu_help: "Help",
    user_menu_contact: "Contact",
    user_menu_logout: "Afmelden",
    user_menu_language: "Taal",
    user_menu_country: "Land",
    user_menu_dashboard: "Dashboard",
    user_menu_salespartner_edit: "VP-pagina bewerken",
    user_menu_app_install: "App installeren",

    footer_copy: "{{currentYear}} proWIN international",

    oauth_logout_headline: "Logout",
    oauth_logout_text: "Logout done",

    link_imprint_href: "/colofon",
    link_imprint_label: "Colofon",
    link_privacy_href: "/gegevensbescherming",
    link_privacy_label: "Gegevensbescherming",
    link_help_href: "/help",
    link_help_label: "Help",
    link_contact_href: "/contact",
    link_contact_label: "Contact",
    imprint_headline: "Colofon",
    privacy_headline: "Gegevensbescherming",

    notifications_choice_title: "Pushmeldingen",
    notifications_ask_to_get_notificatons: "Wil je op de hoogte worden gehouden via pushmeldingen?",
    notifications_ask_to_get_authorization: "Om pushmeldingen te ontvangen, moet je dit activeren in je browser.",
    notifications_choice_intro: "Selecteer de onderwerpen waarvoor een melding moet worden verzonden:",
    notifications_choice_denied: "De pushmeldingen zijn geblokkeerd. Reset de instellingen om pushmeldingen te activeren.",
    notifications_activated: "Pushmeldingen zijn geactiveerd.",
    notifications_turn_off: "Als je geen meldingen meer wilt ontvangen, ga je naar de instellingen van deze webapp op je apparaat en schakel je de meldingen uit.",

    notification_group_general: "Test 1",
    notification_group_marketing: "Test 2",
    notification_group_other: "Test 3",

    install_web_app_General: "De installatie van de proWIN-app is momenteel niet beschikbaar in deze browser. Probeer een andere browser.",
    install_web_app_dialog_title: "Installeer de proWIN-app op je apparaat",

    install_web_app_Firefox: "De proWIN-app installatie is momenteel niet beschikbaar in de Firefox browser.",
    install_web_app_Firefox_Tablet_1: "Klik op het browser-menu",
    install_web_app_Firefox_Tablet_2: "Selecteer 'Toevoegen aan startscherm' in het contextmenu.",
    install_web_app_Firefox_Tablet_3: "Klik op de 'Startpagina'",
    install_web_app_Firefox_3: "Klik op Installeren in het kleine pop-up scherm en je wordt gevraagd om je actie te bevestigen.",

    install_web_app_Opera: "De proWIN-app installatie is momenteel niet beschikbaar in de Opera browser.",
    install_web_app_Opera_Tablet_1: "Klik op het browsermenu",
    install_web_app_Opera_Tablet_2: "Selecteer 'Toevoegen aan' in het contextmenu.",
    install_web_app_Opera_Tablet_3: "Je kunt de app nu een eigen naam geven, die samen met het app-pictogram op je apparaat wordt weergegeven. De naam van de app wordt opgeslagen als voorkeursinstelling.",
    install_web_app_Opera_Tablet_4: "Klik op Toevoegen.",

    install_web_app_Vivaldi_Desktop_1: "Klik met de rechtermuisknop op het tabblad in de tabbladenbalk.",
    install_web_app_Vivaldi_Desktop_2: "Selecteer 'proWIN installeren' in het contextmenu.",
    install_web_app_Vivaldi_Tablet_1: "Klik op het browsermenu",
    install_web_app_Vivaldi_Tablet_2: "Selecteer 'App installeren' of 'Toevoegen aan startscherm' in het contextmenu.",
    install_web_app_Vivaldi_3: "Klik op Installeren in het kleine pop-up scherm en je wordt gevraagd om je actie te bevestigen.",

    install_web_app_SAFARI_1: "Open de deeloptie in je browser.",
    install_web_app_SAFARI_2: "Klik op \"Toevoegen aan beginscherm",
    install_web_app_SAFARI_3: "Je kunt de app nu een eigen naam geven, die samen met het app-pictogram op je apparaat wordt weergegeven. De naam van de app wordt opgeslagen als voorkeursinstelling.",
    install_web_app_SAFARI_4: "Klik op Toevoegen.",

    label_firstname: "Voornaam",
    label_lastname: "Achternaam",
    label_birthday: "Geboortedatum",
    label_title: "Aanhef",
    label_address: "Adres",
    label_phone: "Telefoon",
    label_email: "E-mail",
    label_company: "Bedrijf",
    label_street: "Straat",
    label_housenumber: "Huisnummer",
    label_postalcode: "Postcode",
    label_city: "Woonplaats",
    label_companyname: "Bedrijfsnaam",
    label_div: "Overige",
    label_type_private: "Privé",
    label_type_company: "Zakelijk",
    label_type_div: "Overige",
    label_note: "Notities",
    label_products_desired: "Wensproducten",
    label_contact_request: "Contactaanvraag",
    label_contact_request_phone: "Telefoon",
    label_contact_request_chat: "Chat",
    label_contact_request_post: "Post",
    label_contact_request_email: "E-Mail",
    label_contact_time: "Contacttijden",
    label_contact_time_weekly: "Werkdagen",
    label_contact_time_weekend: "Weekend",
    label_contact_time_morning: "´s morgens",
    label_contact_time_afternoon: "´s middags",
    label_contact_time_evening: "´s avonds",

    title_mr: "Dhr.",
    title_ms: "Mevr.",
    title_div: "Overige",

    network_status: "Je bent offline",
    de_faq_home_headline: "Home bla bla bla ...",

    feature_later: 'Deze functie wordt later toegevoegd',
    feature_only_for_vp: 'Deze functie is alleen voor verkooppartners',

    texthing_error: "Fout bij het ophalen van de gegevens of deze zijn niet beschikbaar in deze taal.",

    employee_logged_as: "Je bent aangemeld als medewerker. Selecteer een optie om door te gaan.",
    employee_to_switch_login: "Naar Switch-login",

    product_sub_navigation: "Product Subnavigatie",
}