export const LOCAL_THEME_MODE = 'theme-mode';

export const HEADER_BG_COLOR = "#DFDFDF";

export const OAUTH2_SWITCH_LOGIN_PATH = process.env.OAUTH2_SWITCH_LOGIN_PATH;
export const CHANGE_LOG_PATH = "/recent-updates";
export const PRODUCT_SUB_NAV_PATH = "/product-sub-nav";
export const OAUTH2_SWITCH_USER_EXIT_URL = process.env.OAUTH2_SWITCH_USER_EXIT_URI;
const NEW_INTRANET_URL = process.env.INTRANET_URL;

// EXTERNAL LINKS
export const CORA_ENDPOINT = process.env.CORA_ENDPOINT;
export const CORA_ENDPOINT_MY_PROFILE = `${CORA_ENDPOINT}/switch/myProfile`;
export const STRUCTURE_APPOINTMENT_URL = `${CORA_ENDPOINT}/switch/customerevents\|list\|upcoming`;
export const REPORTING_URL = `${CORA_ENDPOINT}/switch/reporting\|evaluations`;
export const MARKETING_URL = process.env.MARKETING_URL;
export const SHOP_URL = process.env.SHOP_URL;
export const AKADEMIE_URL = process.env.AKADEMIE_URL;
export const MEDIA_URL = process.env.MEDIA_URL;
export const BLUMER_URL = process.env.BLUMER_URL;
export const VP_SEITE_URL = `${NEW_INTRANET_URL}/_web/vp_website.php`;
export const PRODUCT_INFO_URL = `${NEW_INTRANET_URL}/_web/product_information.php`;
export const REKLAMATION_URL = process.env.COMPLAINTS_SHOP_URL;

export const OAUTH2_LOGIN_URL = process.env.OAUTH2_LOGIN_URI;

export const SWITCH_USER_URL= process.env.OAUTH2_SWITCH_USER_URI;
export const SALESPARTNER_BASE_URL = process.env.SALESPARTNER_URI;

export const KB_FAQ_URI = process.env.KB_FAQ_URL;

export const GA_ID = process.env.GA_ID;


const availableLanguages = ['de', 'fr', 'it', 'nl']
export const defaultLanguage = 'de';
export const KB_FAQ_URL = (language) => availableLanguages.includes(language) ? `${KB_FAQ_URI}/${language}` : `${KB_FAQ_URI}/${defaultLanguage}`;
export const FLAG = (country) => `${CORA_ENDPOINT}/prowincrm/images/flags/flag-${country}.png`
export const ROUTE_HOME = "/home";

export const PROWIN_INTERNET_SITE = "https://prowin.net/";

export const SALESPARTNER_SUB_ACCOUNT_ROUTE = (id: number) => `/api/subaccounts/${id}`;

export const SWITCH_USER_ROUTE = (customerId: number) => `${SWITCH_USER_URL}=${customerId}`;

export const INSTALL_PROMPT = "before-install-prompt-available";
export const WEB_APP_INSTALLED = "is-web-app-installed";